import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(function () {
  let idProductAttribute = document.querySelector('div[data-id-product-attribute]');
  createProductSpin();
  createInputFile();
  zoomImage();
  lightboxImage();
  coverImage();
  imageScrollBox();
  mobileImageScrollBox();
  moveProductAttributes();
  addAccordionActiveClass();
  sldScrollDescription();
  sldInitZoom();
  sldThumbVideo();
  $('body').on('change', '.product-variants *[name]', function () {
    togglePendingRefreshIcon('in');
  });

  prestashop.on('updatedProduct', function (event) {
    createInputFile();
    zoomImage();
    coverImage();
    imageScrollBox();
    mobileImageScrollBox();
    moveProductAttributes();
    sldThumbVideo();
    if (event && event.product_minimal_quantity) {
      const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      const quantityInputSelector = '#quantity_wanted';
      let quantityInput = $(quantityInputSelector);

      quantityInput.trigger('touchspin.updatesettings', {min: minimalProductQuantity});
    }
    
    $('#js_mfp_gallery').replaceWith(event.product_images_modal);
    lightboxImage();
    togglePendingRefreshIcon('out');

    if ($('[data-button-action="add-to-cart"]').is(':disabled')) {
      $('.product-add-to-cart').addClass('add-to-cart-disabled');
    } else {
      $('.product-add-to-cart').removeClass('add-to-cart-disabled');
    }
  });

  if (typeof(varCustomActionAddToCart) !== 'undefined' && varCustomActionAddToCart) {
    $('body').off('click', '[data-button-action="add-to-cart"]');
    $('body').on('click', '[data-button-action="add-to-cart"]', function (event) {
      event.preventDefault();
      var $btn = $(this);
      var psAjaxCart = false;
      if (typeof(varPSAjaxCart) !== 'undefined' && varPSAjaxCart) {
        psAjaxCart = varPSAjaxCart;
      }

      if ($('#quantity_wanted').val() > $('[data-stock]').data('stock') && $('[data-allow-oosp]').data('allow-oosp').length === 0) {
        $btn.attr('disabled', 'disabled');
      } else {
        var _ret = (function () {
          let $form = $(event.target).closest('form');
          let query = $form.serialize() + '&add=1&action=update';
          let actionURL = $form.attr('action');

          let isQuantityInputValid = function($input) {
            var validInput = true;

            $input.each(function (index, input) {
              let $input = $(input);
              let minimalValue = parseInt($input.attr('min'), 10);
              if (minimalValue && $input.val() < minimalValue) {
                onInvalidQuantity($input);
                validInput = false;
              }
            });

            return validInput;
          };

          let onInvalidQuantity = function($input) {
            $input.parents('.product-add-to-cart').first().find('.product-minimal-quantity').addClass('error');
            $input.parent().find('label').addClass('error');
          };

          let $quantityInput = $form.find('input[min]');
          if (!isQuantityInputValid($quantityInput)) {
            onInvalidQuantity($quantityInput);

            return {
              v: undefined
            };
          }

          $btn.removeClass('added').addClass('disabled');

          $.post(actionURL, query, null, 'json').then(function(resp) {
            prestashop.emit('updateCart', {
              reason: {
                idProduct: resp.id_product,
                idProductAttribute: resp.id_product_attribute,
                idCustomization: resp.id_customization,
                linkAction: 'add-to-cart',
                cart: resp.cart
              },
              resp: resp
            });

            if (resp.success) {
              $btn.addClass('added');
              if (!psAjaxCart) {
                window.location.href = prestashop.urls.pages.cart + '?action=show';
              }
            }
            if (resp.hasError) {
              $('.js-modal-message-text').text(resp.errors[0]);
              $('.js-modal-message').modal('show');
            }
          }).fail(function (resp) {
            prestashop.emit('handleError', { eventType: 'addProductToCart', resp: resp });
          });
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    });
  }
});

var productResizeTimer;
$(window).resize(function() {
  clearTimeout(productResizeTimer);
  productResizeTimer = setTimeout(function() {
    zoomImage();
  }, 250);
});

const sldThumbVideo = () => {
  let thumbVideos = document.querySelectorAll('.js-thumb-video');
  let thumbImages = document.querySelectorAll('.js-thumb');
  let coverVideo = document.querySelector('.sld-jd-product-video-cover');
  if (thumbVideos.length > 0 && coverVideo !== undefined) {
    thumbVideos.forEach((thumb) => {
      thumb.addEventListener('click', (e) => {
        let video = e.currentTarget.getAttribute('data-video');
        coverVideo.innerHTML = video;
        coverVideo.classList.remove('invisible');
      });
    });
  }
  if (thumbImages.length > 0) {
    thumbImages.forEach((image) => {
      image.addEventListener('click', (e) => {
        let video = e.currentTarget.getAttribute('data-video');
        coverVideo.innerHTML = '';
        coverVideo.classList.add('invisible');
      });
    });
  }
}

function togglePendingRefreshIcon(fade_status) {
  if (typeof(varProductPendingRefreshIcon) !== 'undefined' && varProductPendingRefreshIcon) {
    if (fade_status == 'in') {
      $('.js-product-refresh-pending-query').fadeIn();
    } else {
      $('.js-product-refresh-pending-query').fadeOut();
    }
  }
}

function zoomImage() {
  $('.zoomWrapper .js-main-zoom').css('position','static').unwrap();
  $('.zoomContainer').remove();

  $('.js-enable-zoom-image .js-main-zoom').elevateZoom({
    zoomType: 'inner',
    gallery: 'js-zoom-gallery',
    galleryActiveClass: 'selected',
    imageCrossfade: true,
    cursor: 'crosshair',
    easing: true,
    easingDuration: 500,
    zoomWindowFadeIn: 500,
    zoomWindowFadeOut: 300,
  });
}

function lightboxImage() {
  var $gallery = $('#js_mfp_gallery');
  if ($gallery.length) {
    var zClose = $gallery.data('text-close'),
        zPrev = $gallery.data('text-prev'),
        zNext = $gallery.data('text-next');

    $('.js_mfp_gallery_item').magnificPopup({
      type: 'image',
      tClose: zClose,
      tLoading: '<div class="uil-spin-css"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>',
      removalDelay: 500,
      mainClass: 'mfp-fade',
      closeOnBgClick: true,
      gallery: {
        enabled: true,
        tPrev: zPrev,
        tNext: zNext,
        tCounter: '%curr% / %total%',
        navigateByImgClick: false,
      },
      image: {
        verticalFit: false,
      },
    });

    $('.js-mfp-button').bind('click', function() {
      var imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');
      $('.js_mfp_gallery_item').filter('[data-id-image="' + imageId + '"]').trigger('click');
    });
  }
}

function coverImage() {
  $('.js-cover-image .js-thumb').on(
    'click',
    function (event) {
      $('.js-thumb.selected').removeClass('selected');
      $(event.currentTarget).addClass('selected');
      if($(event.currentTarget).data('image') !== undefined) {
        $('.js-qv-product-cover').prop('src', $(event.currentTarget).data('image'));
      }
    }
  );
}

function selectImageDeclinaison() {
  let idImageCover = $('.product-mobile-images, #js-zoom-gallery').data('idImageCover');
  $('.product-mobile-images li, #js-zoom-gallery li').each(function(index, el) {
    let $el = $(el);
    console.log($el.data('idImage'), idImageCover)
    if ($el.data('idImage') == idImageCover) {
      let currentImage = $('.product-mobile-images li, #js-zoom-gallery li')[index];
      $('.product-mobile-images, #js-zoom-gallery').prepend(currentImage);
    }
  });
}

function imageScrollBox() {
  $('.js-product-thumbs-scrollbox').makeFlexScrollBox();
  selectImageDeclinaison();
}

function mobileImageScrollBox() {
  $('.js-mobile-images-scrollbox').makeFlexScrollBox();
  selectImageDeclinaison();
}


/*function showHideScrollBoxArrows() {
  var thumbsWidth = 0;
  $('.js-qv-product-images .js-thumb-container').each(function() {
    thumbsWidth = thumbsWidth + $(this).outerWidth();
  });

  if (($('.js-qv-product-images').width() + 5) < thumbsWidth) {
    $('.scroll-box-arrows').addClass('scroll');
  } else {
    $('.scroll-box-arrows').removeClass('scroll');
  }
}*/

function createInputFile() {
  $('.js-file-input').on('change', function(event) {
    let target, file;

    if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
      $(target).prev().text(file.name);
    }
  });
}

function createProductSpin() {
  const $quantityInput = $('#quantity_wanted');
  $quantityInput.makeTouchSpin();

  $('body').on('change keyup', '#quantity_wanted', function (e) {
    $(e.currentTarget).trigger('touchspin.stopspin');
    prestashop.emit('updateProduct', {
      eventType: 'updatedProductQuantity',
      event: e
    });
  });
}

function moveProductAttributes() {
  let $src = $('.js-product-attributes-source'),
      $dest = $('.js-product-attributes-destination'),
      $destBrand = $('.js-product-attributes-brand'),
      $src2 = $('.js-product-availability-source'),
      $dest2 = $('.js-product-availability-destination');
  $dest.empty();
  if ($src.length) {
    if($('body.a-mobile-device')) {
      let $brand = $src.find('.product-manufacturer');
      $src.find('.product-manufacturer').remove();
      $destBrand.html($brand.html());
    }

    $dest.html($src.html()); //$src.remove();
  }
  $dest2.empty();
  if ($src2.length) {
    $dest2.html($src2.html()); //$src2.remove();
  }
}

function addAccordionActiveClass() {
  $('.js-product-accordions [data-toggle="collapse"]').click(function() {
    $(this).closest('.panel').toggleClass('active');
  });
}

function sldScrollDescription() {
  let $link = $('.js-anchor-description');
  let anchor = $link.attr('href');
  if ($link.length > 0 && anchor !== '') {
    $link.on('click', (e) => {
      e.preventDefault();
      $('html, body').animate({scrollTop: $(anchor).offset().top - 100}, 750);
      $('#headingDescription').parent().addClass('active');
      $('#collapseDescription').collapse('show');
    })
  }
}

function toucheMoveInZoom(positionCurrent, positionLast, positionMove, zoomLevel) {
  let left;
  let top;
  const imageSize = {
    height: (positionCurrent.target.offsetHeight * zoomLevel) / 5,
    width: (positionCurrent.target.offsetWidth * zoomLevel) / 5,
  };
  const currentLeft = Math.floor(positionCurrent.clientX);
  const currentTop = Math.floor(positionCurrent.clientY);
  const lastLeft = Math.floor(positionLast.left);
  const lastTop = Math.floor(positionLast.top);
  // calculate position left and top without translate position and zoom level
  left = positionMove.left + ((currentLeft - lastLeft) / zoomLevel);
  top = positionMove.top + ((currentTop - lastTop) / zoomLevel);
  // define left and top if the max position is exceeded
  if (left > imageSize.width || left < -imageSize.width) {
    left = left > 0 ? imageSize.width : -imageSize.width;
  }
  if (top > imageSize.height || top < -imageSize.height) {
    top = top > 0 ? imageSize.height : -imageSize.height;
  }
  return {top: top, left: left}
}

function transformScale(zoomLevel) {
  return 'scale('+zoomLevel+') ';
}

function transformTranslate(position) {

  return 'translate('+position.left+'px,'+position.top+'px)';
}

function startZoom(zoomLevel) {
  const image = document.querySelector('.mfp-img');
  const zoomMore = document.querySelector('.bootstrap-touchspin-up');
  const zoomLess = document.querySelector('.bootstrap-touchspin-down');
  let positionMove = {
    top: 0,
    left: 0,
    init: true,
  };
  let position = {
    top: 0,
    left: 0,
  }
  if (image !== null && zoomMore !== null && zoomLess !== null) {
    zoomMore.addEventListener('click', (e) => {
      e.preventDefault();
      zoomLevel += 1;
      image.style.transform = transformScale(zoomLevel).concat(transformTranslate(positionMove));
    });
    zoomLess.addEventListener('click', (e) => {
      zoomLevel -= 1;
      zoomLevel = zoomLevel < 1 ? 1 : zoomLevel;
      image.style.transform = transformScale(zoomLevel).concat(transformTranslate(positionMove));
    });
    image.addEventListener('touchstart', (e) => {
      e.preventDefault();
      position.left = Math.floor(e.changedTouches[0].clientX);
      position.top = Math.floor(e.changedTouches[0].clientY);
    });
    image.addEventListener('touchmove', (e) => {
      positionMove = toucheMoveInZoom(e.changedTouches[0], position, positionMove, zoomLevel);
      position.left = Math.floor(e.changedTouches[0].clientX);
      position.top = Math.floor(e.changedTouches[0].clientY);
      // change style transform without zoom level and position move
      image.style.transform = transformScale(zoomLevel).concat(transformTranslate(positionMove));
    })
  }
}

function sldInitZoom() {
  const cover = document.querySelector('#product.a-mobile-device .product-cover');
  if (cover !== null) {
    cover.addEventListener('click', (e) => {
      e.preventDefault();
      const imageId = document.querySelector('#js-zoom-gallery .js-thumb.selected').getAttribute('data-id-image');
      if (imageId !== null) {
        $('.js_mfp_gallery_item').filter('[data-id-image="' + imageId + '"]').trigger('click');
        const container = document.querySelector('.mfp-wrap .mfp-container');
        const content = document.querySelector('.mfp-content');
        const title = document.querySelector('.mfp-title');
        const figure = document.querySelector('.mfp-figure figure');
        if (container !== null) {
          container.style.position = 'fixed';
          container.style.left = 'unset';
          container.style.transform = 'unset';
          container.style.top = '20px';
        }
        if (content !== null) {
          content.style.veticalAlign = 'unset';
        }
        if (title !== null) {
          title.style.dispaly = 'contents';
        }
        if (figure !== null) {
          figure.style.overflow = 'hidden';
        }
        const html = '<div class="zoom_popup"><button class="btn btn-touchspin js-touchspin bootstrap-touchspin-down" type="button"><i class="material-icons">remove</i></button><button class="btn btn-touchspin js-touchspin bootstrap-touchspin-up" type="button"><i class="material-icons">add</i></button></div>'

        $('.mfp-title').append(html);
        let zoomLevel = 1;
        startZoom(zoomLevel);
        let arrows = document.querySelectorAll('.mfp-arrow');
        arrows.forEach(arrow => {
          arrow.addEventListener('click', (e) => {
            $('.mfp-title').append(html);
            zoomLevel = 1;
            startZoom(zoomLevel);
          });
        })
      }
    })
  }
}


