import $ from 'jquery';

function initRmaItemSelector() {
  $('#order-return-form table thead input[type=checkbox]').on('click', function() {
    var checked = $(this).prop('checked');
    $('#order-return-form table tbody input[type=checkbox]').each(function(_, checkbox) {
      $(checkbox).prop('checked', checked);
    });
  });
}

function displayFormPreCreateAccount() {
  let formPro = document.querySelector('.customer-form-pro');
  let formCustomer = document.querySelector('.customer-form-customer');
  let titlePro = document.querySelector('.title-form-pro');
  let titleCustomer = document.querySelector('.title-form-customer');
  if (formPro !== null && formCustomer !== null && titlePro !== null && titleCustomer !== null) {
    if (location.search.includes('pro')) {
      formPro.classList.remove('d-none');
      titlePro.classList.remove('d-none');
      formCustomer.classList.add('d-none');
      titleCustomer.classList.add('d-none');
    } else {
      formCustomer.classList.remove('d-none');
      titleCustomer.classList.remove('d-none');
      formPro.classList.add('d-none');
      titlePro.classList.add('d-none');
    }
  }
}

function setupCustomerScripts() {
  if (document.querySelector('body#order-detail') !== null) {
    initRmaItemSelector();
  }
  if (document.querySelector('body#authentication') !== null) {
    displayFormPreCreateAccount()
  }
}

$(document).ready(setupCustomerScripts);
