function updateDropdownPosition()
{
    var $amegamenu = $('#amegamenu');
    if ($amegamenu.length) {
        var updatePosition = function() {
            if ($amegamenu.hasClass('amegamenu_rtl')) {
                updateDropdownPositionRTL($amegamenu);
            }
            else {
                updateDropdownPositionLTR($amegamenu);
            }
        };

        updatePosition();
        $(window).resize(updatePosition);
    }
}

function updateDropdownPositionLTR(mm) {
  const styles = {
    full: 'full',
    initial: 'initial',
    right: 'right',
    left: 'left',
    middle: 'middle',
  };
  const marginLeft = mm.offset().left;
  const marginRight = mm.offset().left + mm.outerWidth();
  // define if dropdown is full
  const styleSelected = styles.full;
  // define if dropdown is align left or right
  const styleAlign = styles.left;

  if (styleSelected === styles.full) {
    document.querySelectorAll('.adropdown').forEach((dropdown) => {
      const dropdownCurrent = dropdown;
      const menuItem = dropdownCurrent.closest('.amenu-item');
      const menuItemLeft = 0 - menuItem.getBoundingClientRect().left;
      dropdownCurrent.style.marginLeft = `${menuItemLeft}px`;
      dropdownCurrent.style.width = `${document.body.offsetWidth}px`;
    });
  } else if (styleSelected === styles.initial) {
    document.querySelectorAll('.adropdown').forEach((dropdown) => {
      const dropdownCurrent = dropdown;
      const menuItem = dropdownCurrent.closest('.amenu-item');
      const menuItemWidth = menuItem.offsetWidth;
      const menuItemLeft = menuItem.offsetLeft;
      const dropdownWidth = dropdownCurrent.offsetWidth;
      const dropdownLeft = dropdownCurrent.offsetLeft;
      const dropdownMiddle = 0 - (dropdownWidth) / 2;
      const dropdownRight = dropdownLeft + dropdownWidth;
      const margin = Number(dropdownCurrent.style.marginLeft.slice(0, -2));

      if (styleAlign === styles.right) {
        if (dropdownRight - margin + dropdownMiddle + menuItemLeft > marginRight) {
          dropdownCurrent.style.marginLeft = `${marginRight - menuItemLeft - dropdownWidth}px`;
        } else {
          dropdownCurrent.style.marginLeft = `${dropdownMiddle}px`;
        }
      } else if (styleAlign === styles.left) {
        if (dropdownLeft - margin + dropdownMiddle + menuItemLeft < -marginLeft && marginLeft !== 0) {
          dropdownCurrent.style.marginLeft = `${-menuItemLeft}px`;
        } else if (marginLeft === 0 && -menuItemLeft > dropdownMiddle) {
          dropdownCurrent.style.marginLeft = `${-menuItemLeft}px`;
        } else {
          dropdownCurrent.style.marginLeft = `${dropdownMiddle + (menuItemWidth / 2)}px`;
        }
      }
    });
  }
}

function updateDropdownPositionRTL(mm)
{
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function() {
    let t = $(this),
        p = t.parent(),
        i = 0 - (t.outerWidth() - p.outerWidth())/2,
        t_right = t.offset().left + t.outerWidth(),
        p_right = p.offset().left + p.outerWidth(),
        margin = parseInt(t.css('margin-right'));

    if (t.offset().left + margin - i < m_left) {
      t.css('margin-right', (0 - (t.outerWidth() - p_right + m_left)) + 'px');
    } else if (t_right + margin - i > m_right) {
      t.css('margin-right', (0 - (m_right - p_right)) + 'px');
    } else {
      t.css('margin-right', i + 'px');
    }
  });
}

function sldCreateButtonBack() {
    const icon = document.createElement('span');
    icon.classList.add('material-icons');
    icon.innerText = 'keyboard_arrow_left';
    const button = document.createElement('button');
    button.classList.add('sld-js-menu-back-button');
    button.append(icon);
    button.setAttribute('data-category-active', '0');
    button.setAttribute('data-category-parent', '');
    return button;
}

function sldShowBackCategory() {
    const categoryActive = this.getAttribute('data-category-active');
    const categoryName = this.getAttribute('data-category-parent');
    const navbarBack = document.querySelector('.sld-menu-navbar-back');
    const navbarTitle = navbarBack.querySelector('.sld-menu-navbar-name');
    if (typeof categoryActive !== 'undefined') {
        const submenuActive = document.querySelector(`ul[data-submenu-id="${categoryActive}"]`);
        const submenuParent = submenuActive.parentElement.closest('ul');
        const submenuParentName = submenuParent.parentElement.closest('ul');
        const submenuParentId = submenuParent.getAttribute('data-submenu-id');
        submenuActive.classList.remove('active');
        if (submenuParentId !== null) {
            this.setAttribute('data-category-active', submenuParentId);
        } else {
            this.setAttribute('data-category-active', 0);
            navbarBack.classList.remove('active');
        }
        if (submenuParentName !== null) {
            const name = submenuParentName.getAttribute('data-category-parent-name');
            navbarTitle.textContent = categoryName;
            this.setAttribute('data-category-parent', name);
        } else {
            navbarTitle.textContent = '';
        }
    }
}

function sidebarClose() {
    document.body.classList.remove('st-effect-left', 'st-menu-open');
    const burgerHeader = document.querySelector('#header .left-nav-trigger');
    if (burgerHeader !== null) {
        burgerHeader.classList.remove('active');
    }
    const navbarBack = document.querySelector('.sld-menu-navbar-back');
    if (navbarBack !== null) {
        navbarBack.classList.remove('active');
    }
    setTimeout(() => {
        const dropdowns = document.querySelectorAll('.adropdown.active, .sld-menu-container-items.active, .sld-category-subs.active');
        const menu = document.querySelector('.anav-top');
        if (dropdowns.length > 0) {
            dropdowns.forEach((dropdown) => {
                dropdown.classList.remove('active');
            });
        }
        if (menu !== null) {
            menu.style.height = '';
        }
    }, 500);
}

function sldGenerateNavBack() {
    const buttonBack = sldCreateButtonBack();
    buttonBack.addEventListener('click', sldShowBackCategory, true);
    const categoryName = document.createElement('div');
    categoryName.classList.add('sld-menu-navbar-name');
    const navbar = document.createElement('div');
    navbar.classList.add('sld-menu-navbar-back');
    const menuBurger = document.createElement('i');
    menuBurger.classList.add('sld-menu-burger');
    menuBurger.append(document.createElement('span'));
    const leftNavIcon = document.createElement('div');
    leftNavIcon.classList.add('left-nav-icon');
    leftNavIcon.append(menuBurger);
    const buttonClose = document.createElement('div');
    buttonClose.classList.add('left-nav-trigger', 'active');
    buttonClose.setAttribute('data-left-nav-trigger', '');
    buttonClose.append(leftNavIcon);
    buttonClose.addEventListener('click', (e) => {
        e.preventDefault();
        sidebarClose();
    });
    navbar.append(buttonBack);
    navbar.append(categoryName);
    navbar.append(buttonClose);
    return navbar;
}


function sldBackLink() {
    //const headerMain = document.querySelector('.header-main');
    const headerMain = document.querySelector('.main-header .header-right');
    const itemsContainer = document.querySelectorAll('.dropdown-content:first-child, .sld-menu-container-items, .sld-category-subs');
    const linksBack = document.querySelector('.sld-js-menu-back-button');
    if (itemsContainer.length > 0 && linksBack === null) {
        headerMain.append(sldGenerateNavBack());
    }
}

function sldActiveSubmenu(event) {
    event.preventDefault();
    const navbarBack = document.querySelector('.sld-menu-navbar-back');
    if (navbarBack !== null) {
        const submenu = this.nextElementSibling;
        const idCategory = submenu.getAttribute('data-submenu-id');
        const nameCategoryParent = submenu.getAttribute('data-category-parent-name');
        const buttonBack = document.querySelector('.sld-js-menu-back-button');
        const categoryParent = document.querySelector('.sld-menu-navbar-name');
        const categoryOld = document.querySelector('.sld-menu-navbar-name');
        let categoryOldName = '';
        if (categoryOld !== null) {
            categoryOldName = categoryOld.textContent;
        }
        buttonBack.setAttribute('data-category-active', idCategory);
        buttonBack.setAttribute('data-category-parent', categoryOldName);
        categoryParent.textContent = nameCategoryParent;
        navbarBack.classList.add('active');
        if (submenu) {
            submenu.classList.add('active');
        }
    }
}

function sldSubMenuSlide() {
    const buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');
    if (buttonSubmenu.length > 0) {
        buttonSubmenu.forEach((button) => {
            button.addEventListener('click', sldActiveSubmenu, true);
        });
    }
}

function sldDisableSubMenuSlide() {
    const buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');
    const dropdowns = document.querySelectorAll('.adropdown');
    if (buttonSubmenu.length > 0) {
        buttonSubmenu.forEach((button) => {
            button.removeEventListener('click', sldActiveSubmenu, true);
        });
    }
    if (dropdowns.length > 0) {
        dropdowns.forEach((dropdown) => {
            const dropdownCurrent = dropdown;
            dropdownCurrent.style.height = null;
        });
    }
}

function sldMenuResponsive(active) {
    if (active) {
        sldBackLink();
        sldSubMenuSlide();
    } else {
        sldDisableSubMenuSlide();
    }
}

function sldMoveMenuDesktop(activeMenuMobile) {
    const containerMain = document.querySelector('.header-display-navfullwidth');
    const containerLeft = document.querySelector('.sidebar-menu');
    const menu = document.querySelector('#amegamenu');
    if (containerMain !== null && containerLeft !== null && menu !== null) {
        // if column left not contains menu
        if (containerLeft.querySelector('#amegamenu') === null && activeMenuMobile) {
            containerLeft.appendChild(menu);
            sldMenuResponsive(true);
        } else if (containerMain.querySelector('#amegamenu') === null && !activeMenuMobile) {
            containerMain.appendChild(menu);
            sldMenuResponsive(false);
        }
    }
}

function sldBreakWidth() {
    if (window.innerWidth < 1260 && !document.body.classList.contains('sld-menu-responsive')) {
        sldMoveMenuDesktop(true);
        document.body.classList.add('sld-menu-responsive');
        const dropdowns = document.querySelectorAll('.adropdown');
        if (dropdowns.length > 0) {
            dropdowns.forEach((dropdown) => {
                const dropdownCurrent = dropdown;
                dropdownCurrent.style.width = '';
                dropdownCurrent.style.marginLeft = '';
                dropdownCurrent.style.height = '';
            });
        }
    } else if (window.innerWidth >= 1260 && document.body.classList.contains('sld-menu-responsive')) {
        sldMoveMenuDesktop(false);
        document.body.classList.remove('sld-menu-responsive');
    }
}

function ajaxLoadDropdownContent() {
    const $ajaxmenu = $('.js-ajax-mega-menu');
    if ($ajaxmenu.length) {
        $.ajax({
            type: 'POST',
            url: $ajaxmenu.data('ajax-dropdown-controller'),
            data: {
                ajax: true,
            },
            dataType: 'json',
            success: function (data) {
                updateDrodownContent(data);
                // if mobile
                if (document.querySelector('#mobile-amegamenu') !== null) {
                    sldMenuResponsive(true);
                } else {
                    // init page
                    sldBreakWidth();
                }
            },
            error: function (err) {
                console.log(err);
            },
        });

        let updateDrodownContent = (dropdown) => {
            $('.js-dropdown-content', $ajaxmenu).each(function () {
                const item = $(this).data('menu-id');
                $(this).replaceWith(dropdown[item]);
            });
        };
    }
}

window.addEventListener('load', () => {
    setTimeout(() => {
        ajaxLoadDropdownContent();
        updateDropdownPosition();
    }, 600);
});

// event resize
window.addEventListener('resize', () => {
    sldBreakWidth();
});
