const initAnchorLink = () => {
  const anchors = document.querySelectorAll('.sld-js-anchor');
  if (anchors.length > 0) {
    anchors.forEach(function(el) {
      el.addEventListener('click', function(e) {
        e.preventDefault();
        let anchor = this.getAttribute('href');
        let anchorTop = document.querySelector(anchor).getBoundingClientRect().top;
        
        $('html, body').animate({scrollTop: anchorTop - 70}, 1000);
      });
    });
  }
}

$('document').ready(function() {
  initAnchorLink();
});